// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import { TweenLite, TimelineLite, Elastic } from 'gsap'
import _ from 'lodash'

const nUpperHeight = 106.21
const nMiddleHeight = 31.37
const nLowerHeight = 106.21
const sHeight = 145.95
const allHeights = [nUpperHeight, nMiddleHeight, nLowerHeight, sHeight]
const totalHeight = _.sum(allHeights)
const heightPercentages = allHeights.map(h => (h / totalHeight) * 100)

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  svg {
    height: 100%;
    display: block;
  }

  .logo {
    overflow: visible;
    cursor: pointer;
  }

  .cls-1 {
    fill: #f4f3f3;
  }
  .cls-2 {
    fill: #dfbb4f;
  }
  .cls-3 {
    fill: #7f8994;
  }
`

type State = {}
type Props = { withText: boolean }

class AnimationElement {
  tween: any
  element: any
  constructor() {
    this.tween = null
    this.element = null
  }
  tweenLiteTo(...args) {
    return TweenLite.from(this.element, ...args)
  }
}

class Logo extends Component<Props, State> {
  static defaultProps = {
    withText: false,
  }
  animationElements = {
    s: new AnimationElement(),
    nLower: new AnimationElement(),
    nMiddle: new AnimationElement(),
    nUpper: new AnimationElement(),
    lineLeft: new AnimationElement(),
    lineMiddle: new AnimationElement(),
    lineRight: new AnimationElement(),
    logo: new AnimationElement(),
    clippingMask: new AnimationElement(),
    text: new AnimationElement(),
  }
  timeline = new TimelineLite({ paused: true })

  width = this.props.withText ? 619.91 : 233

  componentDidMount() {
    const speed = 0.5
    const lineSpeed = 0.2
    const ease = Elastic.easeInOut.config(2, 2)
    const common = { ease }

    this.timeline
      .add('start', 0.5)
      .from(
        this.animationElements.s.element,
        speed,
        { y: 60, ...common },
        'start'
      )
      .from(
        this.animationElements.nUpper.element,
        speed,
        { y: -60, ...common },
        'start'
      )
      .from(
        this.animationElements.nMiddle.element,
        speed,
        { y: -40, ...common },
        'start'
      )
      .from(
        this.animationElements.nLower.element,
        speed,
        { y: -10, ...common },
        'start'
      )
      .from(
        this.animationElements.lineLeft.element,
        lineSpeed,
        { y: -300, opacity: 0 },
        0.9
      )
      .from(
        this.animationElements.lineMiddle.element,
        lineSpeed,
        { y: 300, opacity: 0 },
        0.9
      )
      .from(
        this.animationElements.lineRight.element,
        lineSpeed,
        { y: -300, opacity: 0 },
        0.9
      )

    if (this.props.withText) {
      this.timeline
        .from(
          this.animationElements.logo.element,
          1,
          { x: 210, ease: Elastic.easeInOut.config(2, 3) },
          1.5
        )
        .from(
          this.animationElements.text.element,
          1,
          { x: -400, ease: Elastic.easeInOut.config(2, 3) },
          1.5
        )
    }

    this.timeline.play()
  }

  handleMouseOver = () => {
    this.timeline.reverse()
  }
  handleMouseLeave = () => {
    this.timeline.play()
  }

  restart() {
    this.timeline.restart()
  }

  render() {
    return (
      <Container>
        <svg
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseLeave}
          className='logo'
          xmlns='http://www.w3.org/2000/svg'
          viewBox={`0 0 ${this.width} 363.63`}
          preserveAspectRatio='xMinYMin meet'
        >
          <mask id='cut-off-text' maskContentUnits='userSpaceOnUse'>
            <rect
              ref={div => (this.animationElements.clippingMask.element = div)}
              x='260.32'
              y='0'
              width='999'
              height='363.63'
              fill='white'
            />
          </mask>

          {this.props.withText && (
            <g id='ParentText' mask='url(#cut-off-text)'>
              <g
                id='Text'
                ref={div => (this.animationElements.text.element = div)}
              >
                <path
                  className='cls-1'
                  d='M260.32,43H274c1.39,7.16,5.3,10.32,13.85,10.32,6.33,0,10-2.79,10-7.53,0-4.56-6.42-5.77-14.42-7.44-10-2.24-21.58-5.77-21.58-18.23C261.8,8.28,272,0,286.26,0c15.63,0,24.65,7.91,25.95,21.11H298.73C297.7,14.23,294,11,286.17,11c-5.86,0-9.39,2.79-9.39,7.35,0,5.12,7.16,6.05,15.25,7.72,10,2.05,20.74,5.3,20.74,18,0,12.28-9.3,20.18-24.55,20.18C271.1,64.17,262,55.43,260.32,43Z'
                />
                <path
                  className='cls-1'
                  d='M316.41,51.52c0-9.85,7.81-12.09,17.12-14,7.62-1.58,12-1.86,12.27-4.28V33c0-2.7-2.14-4.65-7.07-4.65s-7.53,2.51-7.81,6v.75H317.44c.18-10.24,8.18-16.93,21.48-16.93s20.55,5.39,20.55,18V63.06H345.71V57c-2.61,4.46-7.54,7.25-14.23,7.25C322.27,64.26,316.41,59.24,316.41,51.52Zm29.39-5.39V41.76c-2.14,1.11-5.3,1.77-8.28,2.51-3.9,1.12-7,2.42-7,6.14,0,2.69,1.95,4.55,5.49,4.55C340.59,55,345.34,52.64,345.8,46.13Z'
                />
                <path
                  className='cls-1'
                  d='M384.28,47.71,393,19.16h12.83L389.68,63.24H376.84L360.1,19.16h14.7Z'
                />
                <path
                  className='cls-1'
                  d='M431.19,47.71l8.74-28.55h12.84L436.59,63.24H423.75L407,19.16H421.7Z'
                />
                <path
                  className='cls-1'
                  d='M452,51.52c0-9.85,7.81-12.09,17.11-14,7.63-1.58,12-1.86,12.28-4.28V33c0-2.7-2.14-4.65-7.07-4.65s-7.53,2.51-7.81,6v.75H453c.19-10.24,8.19-16.93,21.48-16.93S495,23.62,495,36.27V63.06H481.24V57c-2.6,4.46-7.53,7.25-14.23,7.25C457.81,64.26,452,59.24,452,51.52Zm29.39-5.39V41.76c-2.14,1.11-5.3,1.77-8.28,2.51-3.91,1.12-7,2.42-7,6.14,0,2.69,2,4.55,5.49,4.55C476.13,55,480.87,52.64,481.34,46.13Z'
                />
                <path
                  className='cls-1'
                  d='M498.9,55.89l8.65-6.69a15.73,15.73,0,0,0,11.81,5.58c3.63,0,5.86-1.4,5.86-3.91,0-2.69-4.37-3.35-10.23-4.65-7.35-1.58-15.44-4.65-15.44-13.67,0-8.37,7.82-14.51,18.23-14.51,9.3,0,16,3.44,20.46,8.65,0,.19-8.37,6.33-8.37,6.33a15.18,15.18,0,0,0-11.34-5.4c-3.54,0-5.68,1.4-5.68,3.72,0,2.7,2.51,3.16,9.21,4.56,7.53,1.49,16.55,4.37,16.55,13.67,0,8.65-7.9,14.88-18.6,14.88C510.43,64.45,503.55,60.92,498.9,55.89Z'
                />
                <path
                  className='cls-1'
                  d='M305,132.9V94.3H317.7v61.76H303.47l-27.53-42.51v42.51H263.2V94.3h16.65Z'
                />
                <path
                  className='cls-1'
                  d='M340.23,106.49H325.72V94.4h14.51ZM340,156.06H325.91v-43.9H340Z'
                />
                <path
                  className='cls-1'
                  d='M344.94,134c0-13.57,9.2-22.78,23.25-22.78,11.81,0,18.13,5.58,21,14.51l-13,3.44c-1-4.19-3-7.44-7.9-7.44-5.67,0-9,4.65-9,12.09v.56c0,7.53,3.07,12.09,8.74,12.09,5.3,0,7.63-3,8.56-7.91l13,3.07C386.88,151,380.37,157,368,157,353.49,157,344.94,148.06,344.94,134Z'
                />
                <path
                  className='cls-1'
                  d='M407.74,119.51c2.32-5.31,6.6-8.28,12.92-8.28,9.58,0,15.35,6.32,15.35,18.41v26.42H421.87V130.57c0-5.39-1.95-8.18-6.69-8.18s-7.44,3.72-7.44,10.6v23.07H393.79V93.65h13.95Z'
                />
                <path
                  className='cls-1'
                  d='M440.53,134.2c0-13.76,9.3-23,23.71-23s23.63,9.21,23.63,23c0,13.58-9.4,22.79-23.9,22.79S440.53,147.87,440.53,134.2Zm32.92.37v-.65c0-8.09-3.35-12.46-9.3-12.46s-9.21,4.37-9.21,12.46v.65c0,8,3.26,12.19,9.12,12.19S473.45,142.48,473.45,134.57Z'
                />
                <path
                  className='cls-1'
                  d='M507.21,156.06H493.07V93.65h14.14Z'
                />
                <path
                  className='cls-1'
                  d='M512.46,144.52c0-9.85,7.82-12.09,17.12-14,7.62-1.58,12-1.86,12.27-4.28V126c0-2.7-2.14-4.65-7.07-4.65s-7.53,2.51-7.81,6v.75H513.49c.18-10.24,8.18-16.93,21.48-16.93s20.55,5.39,20.55,18v26.79H541.76V150c-2.6,4.46-7.53,7.25-14.23,7.25C518.32,157.26,512.46,152.24,512.46,144.52Zm29.39-5.39v-4.37c-2.14,1.11-5.3,1.77-8.27,2.51-3.91,1.12-7,2.42-7,6.14,0,2.69,1.95,4.55,5.49,4.55C536.64,148,541.39,145.64,541.85,139.13Z'
                />
                <path
                  className='cls-1'
                  d='M559.42,148.89l8.65-6.69a15.73,15.73,0,0,0,11.81,5.58c3.63,0,5.86-1.4,5.86-3.91,0-2.69-4.37-3.35-10.23-4.65-7.35-1.58-15.44-4.65-15.44-13.67,0-8.37,7.81-14.51,18.23-14.51,9.3,0,16,3.44,20.46,8.65,0,.19-8.37,6.33-8.37,6.33a15.22,15.22,0,0,0-11.35-5.4c-3.53,0-5.67,1.4-5.67,3.72,0,2.7,2.51,3.16,9.21,4.56,7.53,1.49,16.55,4.37,16.55,13.67,0,8.65-7.91,14.88-18.6,14.88C571,157.45,564.07,153.92,559.42,148.89Z'
                />
                <path className='cls-1' d='M619.91,156.06H604.75V141h15.16Z' />
                <path
                  className='cls-2'
                  d='M260.32,229H274c1.39,7.16,5.3,10.32,13.85,10.32,6.33,0,10-2.79,10-7.53,0-4.56-6.42-5.77-14.42-7.44-10-2.24-21.58-5.77-21.58-18.23C261.8,194.28,272,186,286.26,186c15.63,0,24.65,7.91,25.95,21.11H298.73c-1-6.88-4.75-10.14-12.56-10.14-5.86,0-9.39,2.79-9.39,7.35,0,5.12,7.16,6.05,15.25,7.72,10,2.05,20.74,5.3,20.74,18,0,12.28-9.3,20.18-24.55,20.18C271.1,250.17,262,241.43,260.32,229Z'
                />
                <path
                  className='cls-2'
                  d='M315.74,227.2c0-13.76,9.3-23,23.72-23s23.62,9.21,23.62,23c0,13.58-9.39,22.79-23.9,22.79S315.74,240.87,315.74,227.2Zm32.92.37v-.65c0-8.09-3.34-12.46-9.29-12.46s-9.21,4.37-9.21,12.46v.65c0,8,3.25,12.19,9.11,12.19S348.66,235.48,348.66,227.57Z'
                />
                <path
                  className='cls-2'
                  d='M369.78,215.48h-6.42V205.16h6.42v-2.79c0-10.88,3.06-15,12.83-15.81h9.58v10.88h-2.88c-5.21,0-6.33.28-6.14,6.51l.09,1.21h8.28v10.32h-8v33.58H369.78Z'
                />
                <path
                  className='cls-2'
                  d='M410.23,249.06c-9.12-.66-10.88-4.84-11.07-12.65v-21.3H393.3v-10h5.86V196.6L413,189.81v15.35h7.9v10h-8v20.74c0,2,.93,2.79,2.88,2.88h4.83v10.33Z'
                />
                <path
                  className='cls-2'
                  d='M443.33,230.64l7.54-25.48h12.37l7.34,25.48,6.79-25.48H490l-13.95,43.9H463.89l-7.81-27.63-7.82,27.63H436l-13.77-43.9h14.33Z'
                />
                <path
                  className='cls-2'
                  d='M487.88,237.52c0-9.85,7.81-12.09,17.11-14,7.63-1.58,12-1.86,12.28-4.28V219c0-2.7-2.14-4.65-7.07-4.65s-7.53,2.51-7.81,6v.75H488.91c.18-10.24,8.18-16.93,21.48-16.93s20.55,5.39,20.55,18v26.79H517.18V243c-2.61,4.46-7.54,7.25-14.23,7.25C493.74,250.26,487.88,245.24,487.88,237.52Zm29.39-5.39v-4.37c-2.14,1.11-5.3,1.77-8.28,2.51-3.9,1.11-7,2.42-7,6.14,0,2.69,2,4.55,5.49,4.55C512.06,241,516.81,238.64,517.27,232.13Z'
                />
                <path
                  className='cls-2'
                  d='M550.66,212.79c1.39-4.84,4.83-7.91,12.37-7.91h1v12.74h-5.21c-6.51,0-8.18,2.88-8.18,9.49v21.95H536.43v-43.9h14.23Z'
                />
                <path
                  className='cls-2'
                  d='M564.05,227.39c0-13.86,9.11-23.16,22.88-23.16,14,0,22.22,8.74,22.22,23.25v3.44H577.63c.65,6,4.46,9,10.13,9s8.47-2.79,9.77-6.42L609,237c-3,8-9.49,13-21.77,13C572.79,250,564.05,241.24,564.05,227.39Zm13.58-4.65H595.3c-.38-5.86-3.17-8.75-8.56-8.75C581.81,214,578.28,217.16,577.63,222.74Z'
                />
                <path
                  className='cls-2'
                  d='M298,316.29H277.89v14.14h32.46v11.63H263.2V280.3h46.31v11.44H277.89v13H298Z'
                />
                <path
                  className='cls-2'
                  d='M329.32,305.13c2.33-5.11,6.7-8.09,13-8.09,9.67,0,15.35,6.51,15.35,18.6v26.42H343.55V316.57c0-5.39-1.95-8.18-6.7-8.18-4.55,0-7.44,3.72-7.44,10.6v23.07H315.28v-43.9h14Z'
                />
                <path
                  className='cls-2'
                  d='M374.89,329.22c-1.95.19-3.26,1.12-3.26,2.33,0,1.48,2,2.51,5.12,2.51h11.44c13.48,0,20.74,4.93,20.74,13.95,0,10-8.93,15.62-25.21,15.62-15.81,0-23.8-4.18-23.8-12.09,0-4.28,2.79-7.44,7.9-9.3-4.28-1.11-6.79-3.9-6.79-7.62s2.79-6.7,7.72-8.28a14.92,14.92,0,0,1-6.6-12.56c0-9.58,8.65-16.64,20.55-16.64,7.44,0,13.49,2.88,16.84,7.53,1.58-4.09,4.55-6.51,9-6.51v12.27a6.42,6.42,0,0,0-6.42.56,11.6,11.6,0,0,1,.28,2.61c0,10.13-7.91,16.55-20.28,16.55A25.85,25.85,0,0,1,374.89,329.22Zm3.25,16.28c-4.27.37-7.16,2-7.16,4.93,0,3.07,4.28,4.83,13,4.83s13.4-1.86,13.4-5.11-3.07-4.65-10.7-4.65ZM390,313.88v-.47c0-4.37-3.07-7.35-7.54-7.35a7.11,7.11,0,0,0-7.44,7.35V314c0,4.37,2.89,7.16,7.44,7.16S390,318.43,390,313.88Z'
                />
                <path
                  className='cls-2'
                  d='M426,292.49H411.53v-12.1H426Zm-.19,49.57H411.72v-43.9h14.13Z'
                />
                <path
                  className='cls-2'
                  d='M446.13,305.13c2.32-5.11,6.69-8.09,13-8.09,9.67,0,15.34,6.51,15.34,18.6v26.42H460.36V316.57c0-5.39-2-8.18-6.7-8.18-4.56,0-7.44,3.72-7.44,10.6v23.07H432.08v-43.9h14.05Z'
                />
                <path
                  className='cls-2'
                  d='M478,320.39c0-13.86,9.12-23.16,22.88-23.16,14.05,0,22.23,8.74,22.23,23.25v3.44H491.6c.65,5.95,4.47,9,10.14,9s8.46-2.79,9.76-6.42L522.94,330c-3,8-9.48,13-21.76,13C486.77,343,478,334.24,478,320.39Zm13.58-4.65h17.67c-.37-5.86-3.16-8.75-8.55-8.75C495.79,307,492.25,310.16,491.6,315.74Z'
                />
                <path
                  className='cls-2'
                  d='M525.36,320.39c0-13.86,9.11-23.16,22.88-23.16,14,0,22.23,8.74,22.23,23.25v3.44H538.94c.65,5.95,4.46,9,10.14,9s8.46-2.79,9.76-6.42L570.28,330c-3,8-9.49,13-21.76,13C534.1,343,525.36,334.24,525.36,320.39Zm13.58-4.65h17.67c-.37-5.86-3.16-8.75-8.56-8.75C543.12,307,539.59,310.16,538.94,315.74Z'
                />
                <path
                  className='cls-2'
                  d='M588.69,305.79c1.4-4.84,4.84-7.91,12.37-7.91h1v12.74h-5.2c-6.51,0-8.19,2.88-8.19,9.49v21.95H574.46v-43.9h14.23Z'
                />
                <path className='cls-2' d='M613.15,342.06H598V327h15.16Z' />
              </g>
            </g>
          )}

          <g id='Logo' ref={div => (this.animationElements.logo.element = div)}>
            <g id='S' ref={div => (this.animationElements.s.element = div)}>
              <g id='S-2' data-name='S'>
                <path
                  className='cls-3'
                  d='M96.07,215.69l-57,33.13,0,12.4,57-33.13c3.12-1.81,4.69-4.18,4.69-6.56l0-12.41C100.75,211.5,99.19,213.88,96.07,215.69Z'
                />
                <path
                  className='cls-3'
                  d='M196.23,264.73l-28.34-16.36a3.81,3.81,0,0,0-.61-.29,4,4,0,0,0-.52-.18l-.25-.06-.06,0-.13,0-.43-.07-.42-.05-.42,0h-.44l-.48,0-.55.08-.22.05-.21.05-.38.1a4.6,4.6,0,0,0-.93.41l-9.16,5.32-.05,0c-.08.05-.14.11-.21.16a3.3,3.3,0,0,0-.3.22l0,0a3.5,3.5,0,0,0-.27.28l-.05.07a.43.43,0,0,1,0,.07l-.08.12c0,.08-.08.16-.12.24s0,0,0,0,0,0,0,.05a.83.83,0,0,0,0,.15,1.13,1.13,0,0,0,0,.25v0l0,12.4a1.75,1.75,0,0,1,.07-.44s0,0,0-.05a1.45,1.45,0,0,1,.2-.39l.05-.07a2,2,0,0,1,.34-.38l.29-.22c.09-.06.17-.13.27-.19l9.16-5.32a5.1,5.1,0,0,1,1.31-.5l.2,0a6.65,6.65,0,0,1,1.48-.17h.06a6.58,6.58,0,0,1,1.55.18h.06a5.35,5.35,0,0,1,1.39.53l28.34,16.36a2.52,2.52,0,0,1,1.19,1.3,1.61,1.61,0,0,1,.08.48l0-12.4A2.13,2.13,0,0,0,196.23,264.73Z'
                />
                <path
                  className='cls-3'
                  d='M68.56,270.25,40.22,253.89a5.82,5.82,0,0,0-1.12-.47l-.27-.06,0,0-.13,0-.42-.07-.43-.05-.42,0h-.45l-.48,0-.55.08-.22.06-.21,0c-.12,0-.26.05-.38.09a4.51,4.51,0,0,0-.92.41L25,259.21l-.05,0-.23.17-.28.21,0,0a3.5,3.5,0,0,0-.27.28l0,.08-.05.07-.07.11c-.05.08-.08.16-.12.24a.09.09,0,0,1,0,.05.43.43,0,0,0,0,0,.88.88,0,0,0-.05.15,2,2,0,0,0,0,.25v0l0,12.4a1.62,1.62,0,0,1,.07-.44l0-.05a1.36,1.36,0,0,1,.2-.4l.05-.07a2,2,0,0,1,.34-.38,2,2,0,0,1,.28-.2,2.69,2.69,0,0,1,.27-.2l9.17-5.33a5.39,5.39,0,0,1,1.31-.5l.2-.05a6.67,6.67,0,0,1,1.49-.17h0a6.72,6.72,0,0,1,1.56.18h.06a5,5,0,0,1,1.38.53l28.35,16.36a2.16,2.16,0,0,1,1.26,1.78l0-12.41A2.15,2.15,0,0,0,68.56,270.25Z'
                />
                <path
                  className='cls-3'
                  d='M145.86,257.64l-57,33.13a17.27,17.27,0,0,1-2.25,1.07l-.63.24c-.49.17-1,.32-1.51.46s-1.07.29-1.62.4-1.3.26-2,.34l-.47.07c-.9.09-1.81.14-2.72.15h-.44c-.89,0-1.79-.06-2.68-.15l-.4,0c-.88-.11-1.75-.26-2.6-.45l-.46-.11a22.43,22.43,0,0,1-2.24-.68l-.4-.14A17.91,17.91,0,0,1,66,290.77L4.8,255.44C1.62,253.6,0,251.18,0,248.77l0,12.4c0,2.42,1.58,4.83,4.77,6.67L66,303.17a17.15,17.15,0,0,0,2.32,1.12l.14,0,.4.14c.47.17.93.34,1.43.48.26.08.54.13.81.2l.46.11c.14,0,.27.08.41.1.53.11,1.06.21,1.6.28l.59.07.4,0,.59.07c.52,0,1.06.07,1.59.08.17,0,.33,0,.5,0h1.16c.6,0,1.2-.06,1.79-.12l.2,0,.48-.07c.46-.06.93-.11,1.38-.19.2,0,.39-.1.58-.15.56-.11,1.09-.25,1.62-.4.29-.08.59-.12.86-.21l.66-.25c.21-.08.42-.15.62-.24a16.19,16.19,0,0,0,2.26-1.07l57-33.12,45.91,26.5,0-12.4Z'
                />
                <path
                  className='cls-3'
                  d='M200.26,297.86,191,292.54a5.76,5.76,0,0,0-.61-.3l-.52-.17-.26-.07h-.06l-.13,0-.43-.08-.42,0-.42,0h-.45a3.87,3.87,0,0,0-.48,0l-.55.07-.23.06-.2,0-.38.1a3.92,3.92,0,0,0-.93.41L156.81,308.9l-.05,0a2.09,2.09,0,0,0-.22.16,2.21,2.21,0,0,0-.29.22l0,0a2.37,2.37,0,0,0-.27.28.43.43,0,0,1,0,.07l0,.07-.08.12a2.56,2.56,0,0,0-.11.24.06.06,0,0,0,0,0l0,.05c0,.05,0,.1,0,.15a1.07,1.07,0,0,0,0,.25v0l0,12.4a1.35,1.35,0,0,1,.07-.44.43.43,0,0,0,0-.05,2.4,2.4,0,0,1,.2-.39l.05-.07a3,3,0,0,1,.34-.38l.29-.22c.09-.06.17-.13.27-.19l28.16-16.36a5,5,0,0,1,1.31-.51l.21-.05a7.29,7.29,0,0,1,1.48-.16H188a7.31,7.31,0,0,1,1.56.17l.06,0a5.08,5.08,0,0,1,1.4.53l9.21,5.32a2.14,2.14,0,0,1,1.26,1.76l0-12.4A2.1,2.1,0,0,0,200.26,297.86Z'
                />
                <path
                  className='cls-3'
                  d='M230.76,284.67c0,.17,0,.34-.06.51s-.07.28-.1.41a3.94,3.94,0,0,1-.14.49c-.05.13-.11.26-.17.39a4.52,4.52,0,0,1-.23.5c-.07.13-.15.24-.22.36s-.24.38-.38.57a4,4,0,0,1-.26.32,9.46,9.46,0,0,1-.69.74l-.26.26-.5.41-.45.36c-.18.13-.38.26-.58.38s-.39.27-.61.4L157.7,330.52a17.27,17.27,0,0,1-2.25,1.07l-.63.24c-.49.17-1,.32-1.51.46s-1.07.29-1.62.4-1.3.26-2,.34l-.48.07c-.9.09-1.81.14-2.72.15h-.43c-.9,0-1.8-.06-2.69-.15l-.4,0c-.88-.11-1.75-.26-2.6-.45l-.46-.11a23.25,23.25,0,0,1-2.24-.68l-.4-.14a17.91,17.91,0,0,1-2.46-1.16,10.2,10.2,0,0,1-4.11-4,5.8,5.8,0,0,1-.66-2.68h0l0,12.4c0,2.41,1.59,4.83,4.78,6.67a17.74,17.74,0,0,0,2.32,1.12l.14,0c.13.06.27.09.4.14.47.17.94.34,1.43.48.26.08.54.13.81.2l.46.11.41.11c.53.1,1.06.2,1.6.27l.59.07.4,0,.59.07c.53,0,1.06.07,1.59.08.17,0,.33,0,.5,0h.43c.24,0,.49,0,.73,0,.6,0,1.2-.06,1.79-.12l.2,0,.48-.07c.46-.06.93-.11,1.38-.19l.58-.14c.56-.12,1.09-.26,1.62-.41.29-.08.59-.12.86-.21s.44-.17.66-.25l.63-.24a16.62,16.62,0,0,0,2.25-1.07l68.4-39.75.2-.11c.15-.09.28-.19.42-.28l.57-.39c.17-.12.31-.24.46-.36s.34-.27.49-.41l.1-.08a1.45,1.45,0,0,0,.17-.18,7.65,7.65,0,0,0,.68-.73l.15-.16c.05-.06.07-.12.12-.17s.26-.37.37-.56.12-.16.16-.24a.67.67,0,0,0,.06-.12c.09-.17.16-.34.24-.5s.1-.2.13-.3a.38.38,0,0,0,0-.1,4.34,4.34,0,0,0,.14-.48c0-.11.07-.22.1-.33a.28.28,0,0,1,0-.09,4.4,4.4,0,0,0,.05-.51c0-.11,0-.22,0-.33v-.14l0-12.4C230.8,284.36,230.77,284.51,230.76,284.67Z'
                />
                <path
                  className='cls-1'
                  d='M226,277.53c6.33,3.66,6.36,9.59.08,13.24L157.7,330.52c-6.28,3.65-16.52,3.65-22.85,0s-6.37-9.59-.08-13.24l57-33.13-45.91-26.51-57,33.13c-6.28,3.65-16.52,3.65-22.85,0L4.8,255.44c-6.32-3.66-6.36-9.59-.07-13.24l68.4-39.75c6.29-3.65,16.53-3.65,22.85,0s6.37,9.59.09,13.24l-57,33.13L77.32,270.9l57-33.12c6.28-3.66,16.52-3.66,22.86,0Zm-166.6,1.56,9.16-5.33c1.66-1,1.65-2.54,0-3.51L40.22,253.89a6.71,6.71,0,0,0-6,0L25,259.21c-1.65,1-1.64,2.55,0,3.51l28.34,16.36a6.73,6.73,0,0,0,6.07,0Zm127.68-5.52,9.16-5.33c1.66-1,1.64-2.54,0-3.51l-28.34-16.36a6.68,6.68,0,0,0-6,0l-9.16,5.32c-1.66,1-1.64,2.54,0,3.51l28.35,16.36a6.7,6.7,0,0,0,6.06,0Z'
                />
                <path
                  className='cls-3'
                  d='M104.38,268.15c4.23,2.44,11.06,2.44,15.25,0s4.18-6.39,0-8.83a16.82,16.82,0,0,0-15.26,0c-4.19,2.44-4.17,6.39,0,8.83Z'
                />
                <path
                  className='cls-3'
                  d='M77,213.5a16.8,16.8,0,0,0,15.25,0c4.19-2.44,4.17-6.4,0-8.84s-11.06-2.44-15.25,0-4.18,6.4,0,8.84'
                />
                <path
                  className='cls-3'
                  d='M10.79,252.06a16.8,16.8,0,0,0,15.25,0c4.19-2.44,4.17-6.39-.05-8.83s-11.06-2.44-15.25,0-4.18,6.39,0,8.83Z'
                />
                <path
                  className='cls-3'
                  d='M138.62,328.32c4.23,2.44,11.06,2.44,15.25,0s4.18-6.39-.05-8.83a16.8,16.8,0,0,0-15.25,0c-4.19,2.44-4.17,6.39.05,8.83'
                />
                <path
                  className='cls-3'
                  d='M207,288.58c4.23,2.44,11.06,2.44,15.25,0s4.18-6.4,0-8.84a16.82,16.82,0,0,0-15.26,0c-4.19,2.44-4.17,6.4,0,8.84'
                />
              </g>
            </g>
            <g
              id='N_Lower'
              data-name='N Lower'
              ref={div => (this.animationElements.nLower.element = div)}
            >
              <g id='N_Lower-2' data-name='N Lower'>
                <path
                  className='cls-3'
                  d='M114.67,162.56,67.19,135.14c-.18-.1-.39-.2-.6-.29l-.52-.17-.26-.06-.06,0-.12,0-.43-.07-.42-.05-.43,0h-.44a3.87,3.87,0,0,0-.48,0,4.85,4.85,0,0,0-.55.08l-.23.05-.2,0-.38.1a4.92,4.92,0,0,0-.93.4L52,140.47l0,0-.22.17-.29.21s0,0,0,0a2.44,2.44,0,0,0-.27.29l0,.07a.43.43,0,0,0,0,.07.76.76,0,0,0-.08.11,1.92,1.92,0,0,0-.12.25.08.08,0,0,1,0,0s0,0,0,0a1.42,1.42,0,0,0-.05.16,1.94,1.94,0,0,0,0,.24v0l0,12.41a1.62,1.62,0,0,1,.07-.44s0,0,0-.05a1.36,1.36,0,0,1,.2-.4l0-.07a2,2,0,0,1,.34-.38c.08-.07.19-.14.29-.21s.17-.13.27-.19l9.17-5.33a5.17,5.17,0,0,1,1.3-.5l.21-.05a6.58,6.58,0,0,1,1.48-.17h.06a7.31,7.31,0,0,1,1.56.17l0,0a5,5,0,0,1,1.39.53L114.64,175a2.12,2.12,0,0,1,1.25,1.76l0-12.4A2.13,2.13,0,0,0,114.67,162.56Z'
                />
                <path
                  className='cls-1'
                  d='M160.52,197l-.66.42-.8.43c-.24.13-.5.24-.76.36s-.58.27-.9.39-.52.2-.8.29l-1,.31a16.49,16.49,0,0,1-1.93.47l-1,.18c-.32.06-.68.1-1,.15l-1,.1-1.07.06-1.08,0a11,11,0,0,1-1.15,0c-.28,0-.54,0-.82,0-.45,0-.86-.06-1.3-.12s-.69-.09-1-.14a21.18,21.18,0,0,1-7.11-2.4l-130-75.08c-6.35-3.67-6.38-9.58-.08-13.24s16.52-3.67,22.87,0l130,75.07.7.45c.22.14.43.29.62.43a7.1,7.1,0,0,1,.67.53c.19.17.38.34.55.52a6.42,6.42,0,0,1,.51.54c.18.2.34.42.5.63a6.53,6.53,0,0,1,.62,1.08c.09.19.18.39.26.59a3.37,3.37,0,0,1,.19.67,4.94,4.94,0,0,1,.1.53,4.64,4.64,0,0,1,0,1.29c0,.18-.07.37-.09.55a5.93,5.93,0,0,1-.2.69,6.29,6.29,0,0,1-.26.65c-.09.19-.19.39-.3.58a4.87,4.87,0,0,1-.41.57,4.57,4.57,0,0,1-.42.54,5.08,5.08,0,0,1-.5.54,8.58,8.58,0,0,1-1.14,1C160.94,196.69,160.75,196.85,160.52,197Zm-55-25.6,9.17-5.33c1.66-1,1.65-2.54,0-3.5L67.19,135.14a6.71,6.71,0,0,0-6,0L52,140.47c-1.66,1-1.64,2.55,0,3.51l47.48,27.41a6.68,6.68,0,0,0,6,0Zm35.24,23.84a16.83,16.83,0,0,0,15.25,0c4.2-2.44,4.16-6.4,0-8.84a16.8,16.8,0,0,0-15.25,0c-4.2,2.44-4.18,6.4,0,8.84Zm-130-75.09a16.8,16.8,0,0,0,15.25,0c4.2-2.44,4.17-6.39-.05-8.83a16.83,16.83,0,0,0-15.25,0c-4.2,2.44-4.17,6.4.05,8.83Z'
                />
                <path
                  className='cls-3'
                  d='M164.49,191.44c0,.17-.08.36-.1.55-.09.35-.15.52-.2.69s-.18.44-.27.65-.19.39-.29.59-.26.39-.41.57a4.67,4.67,0,0,1-.42.53c-.16.19-.31.36-.5.55a9.34,9.34,0,0,1-1.14,1l-.65.46c-.2.13-.41.28-.64.41l-.8.42c-.25.14-.51.25-.76.36s-.59.27-.91.4l-.8.29-1,.32a16.18,16.18,0,0,1-1.94.46c-.32.08-.67.13-1,.19s-.68.09-1,.13-.68.08-1,.1-.72,0-1.07.07l-1.08,0a10.67,10.67,0,0,1-1.14,0c-.29,0-.55,0-.84,0s-.85-.08-1.29-.12l-1-.15c-.53-.09-1-.21-1.54-.32-.3-.06-.59-.14-.85-.21s-.61-.17-.92-.27-.7-.23-1-.34l-.71-.27a18.5,18.5,0,0,1-2.05-1L16.46,127.84l-9.52-5.49c-3.2-1.85-4.8-4.26-4.8-6.68l0,12.4c0,2.42,1.59,4.83,4.79,6.68l9.56,5.51,120.47,69.57a19.56,19.56,0,0,0,2.06,1l.26.12.44.15c.34.11.68.25,1,.36.16,0,.29.1.44.13a4.21,4.21,0,0,0,.48.12l.85.21.5.13,1,.19.61.1.44,0c.29,0,.55.06.82.08a4.07,4.07,0,0,0,.47,0c.29,0,.55,0,.84,0h.06l.47,0h.61c.32,0,.63,0,.93,0h.29l.93-.05.59-.06.46-.06c.32,0,.64-.07,1-.13h.05l.61-.11a3.83,3.83,0,0,0,.42-.08h0a14.63,14.63,0,0,0,1.9-.46l1-.32.8-.29a8.34,8.34,0,0,0,.91-.4l.76-.34.8-.43a1,1,0,0,1,.15-.1l.52-.32.62-.46a9.33,9.33,0,0,0,.88-.7,3.25,3.25,0,0,0,.26-.27,4.29,4.29,0,0,0,.5-.55c.07-.06.13-.13.19-.19l.23-.34a.47.47,0,0,0,.09-.12c.12-.13.21-.3.33-.45a.06.06,0,0,1,0,0c.1-.17.19-.36.29-.55s.13-.31.19-.48l.06-.17a7.06,7.06,0,0,0,.21-.69c0-.19.07-.36.09-.55a4,4,0,0,0,0-.49v-.12l0-12.4C164.53,191,164.51,191.23,164.49,191.44Z'
                />
                <path
                  className='cls-3'
                  d='M140.74,195.23a16.83,16.83,0,0,0,15.25,0c4.2-2.44,4.17-6.4-.05-8.84a16.8,16.8,0,0,0-15.25,0c-4.2,2.44-4.17,6.4.05,8.84'
                />
                <path
                  className='cls-3'
                  d='M10.71,120.14a16.8,16.8,0,0,0,15.25,0c4.2-2.44,4.17-6.4,0-8.83a16.82,16.82,0,0,0-15.26,0c-4.19,2.44-4.17,6.39.05,8.83'
                />
              </g>
            </g>
            <g
              id='N_Middle'
              data-name='N Middle'
              ref={div => (this.animationElements.nMiddle.element = div)}
            >
              <g id='N_Middle-2' data-name='N Middle'>
                <path
                  className='cls-1'
                  d='M231.27,105.24c0,5.17-7.22,9.36-16.14,9.36l-196.76-.24a25.58,25.58,0,0,1-7.55-1.09A18.72,18.72,0,0,1,7,111.61c-2.94-1.69-4.76-4-4.77-6.63,0-5.18,7.22-9.36,16.15-9.35l196.76.24a23.28,23.28,0,0,1,11.42,2.74C229.45,100.31,231.27,102.65,231.27,105.24ZM13.32,110.52c5.26,1.61,11.79.46,14.57-2.59,2.25-2.48,1.34-5.46-1.94-7.35a12.28,12.28,0,0,0-2.58-1.1C18.11,97.87,11.58,99,8.8,102.07c-2.25,2.48-1.32,5.46,2,7.35a12.1,12.1,0,0,0,2.56,1.1Zm196.75.22c5.28,1.62,11.81.46,14.59-2.58,2.26-2.49,1.33-5.46-1.94-7.35a12.52,12.52,0,0,0-2.58-1.1c-5.26-1.62-11.79-.46-14.56,2.58-2.27,2.49-1.34,5.47,1.94,7.36a12.78,12.78,0,0,0,2.55,1.09Zm-102.36-.36c5.28,1.62,11.79.47,14.58-2.58,2.26-2.48,1.34-5.46-1.94-7.35a11.9,11.9,0,0,0-2.57-1.09c-5.27-1.62-11.79-.47-14.58,2.58-2.26,2.48-1.32,5.46,2,7.35a12,12,0,0,0,2.56,1.09Z'
                />
                <path
                  className='cls-3'
                  d='M231.22,105.84a3,3,0,0,1-.05.43,4.07,4.07,0,0,1-.13.5c0,.14-.07.28-.12.42s-.12.28-.18.42-.14.33-.23.49l-.2.31c-.13.21-.26.41-.41.61s-.16.19-.24.29a9.2,9.2,0,0,1-.68.73l-.3.29-.53.44-.42.32c-.2.15-.41.28-.63.42l-.42.27a15.9,15.9,0,0,1-1.54.8l-.38.16q-.75.33-1.56.6l-.24.09a23.87,23.87,0,0,1-3,.74h0c-.6.1-1.22.19-1.86.25-.22,0-.44,0-.67.06l-1,.07c-.43,0-.86,0-1.3,0l-196.76-.24c-.56,0-1.1,0-1.65-.06-.16,0-.32,0-.48,0-.6,0-1.19-.1-1.77-.19l-.38-.06-1.32-.25-.75-.18c-.41-.11-.82-.2-1.2-.32-.61-.19-1.17-.41-1.73-.63a18.43,18.43,0,0,1-2.14-1c-2.94-1.69-4.76-4-4.77-6.63l0,12.4C2.16,120,4,122.32,6.92,124a18.47,18.47,0,0,0,2.14,1l.21.1c.49.19,1,.37,1.51.53l.5.15.7.17c.25.06.5.13.75.18l.22.06,1.11.19c.13,0,.25,0,.38.06l.07,0c.5.07,1,.13,1.54.17h.16l.48,0c.31,0,.62,0,.94,0h.71L215.1,127l1,0,.31,0c.34,0,.67,0,1-.06l.47,0,.2,0c.63-.07,1.25-.15,1.86-.26h0a23.87,23.87,0,0,0,3-.74H223l.18-.08c.55-.18,1.07-.38,1.57-.59l.37-.17a16.09,16.09,0,0,0,1.55-.8l.08,0c.12-.07.22-.15.34-.23a6.74,6.74,0,0,0,.62-.42c.15-.1.28-.21.42-.32l.53-.44.15-.12c.06-.06.1-.12.16-.17.24-.24.47-.48.68-.73.05-.06.11-.11.16-.18a1,1,0,0,0,.08-.11,7,7,0,0,0,.4-.6l.17-.25a.36.36,0,0,0,0-.07,5.24,5.24,0,0,0,.23-.48c.05-.12.12-.24.17-.36s0,0,0-.07.08-.28.12-.42.09-.27.12-.42a.17.17,0,0,1,0-.07c0-.15,0-.29.05-.43s0-.28,0-.43a.91.91,0,0,0,0-.17l0-12.41A5.82,5.82,0,0,1,231.22,105.84Z'
                />
                <path
                  className='cls-3'
                  d='M27.9,107.91c-1.6,1.77-4.47,2.89-7.63,3.2a17.32,17.32,0,0,1-3.81,0,18.08,18.08,0,0,1-3.13-.63,13,13,0,0,1-2.57-1.1c-3.27-1.89-4.2-4.87-1.94-7.35,1.62-1.78,4.49-2.91,7.64-3.23a20.22,20.22,0,0,1,3.81,0,16.44,16.44,0,0,1,3.12.63A12.11,12.11,0,0,1,26,100.55C29.25,102.45,30.16,105.42,27.9,107.91Z'
                />
                <path
                  className='cls-3'
                  d='M107.72,110.39c5.26,1.61,11.79.46,14.57-2.59,2.26-2.48,1.34-5.46-1.94-7.35a12.28,12.28,0,0,0-2.58-1.1c-5.26-1.61-11.79-.46-14.57,2.59-2.25,2.48-1.32,5.46,2,7.35a12.1,12.1,0,0,0,2.56,1.1'
                />
                <path
                  className='cls-3'
                  d='M210.09,110.74c5.26,1.61,11.79.46,14.57-2.58,2.25-2.49,1.33-5.46-1.94-7.35a12.28,12.28,0,0,0-2.58-1.1c-5.26-1.62-11.79-.47-14.57,2.58-2.26,2.49-1.33,5.46,2,7.36a13,13,0,0,0,2.57,1.09'
                />
              </g>
            </g>
            <g
              id='N_Top'
              data-name='N Top'
              ref={div => (this.animationElements.nUpper.element = div)}
            >
              <g id='N_Upper' data-name='N Upper'>
                <path
                  className='cls-3'
                  d='M181.46,65,134,37.58a5.71,5.71,0,0,0-.61-.29l-.52-.17-.26-.07h-.06l-.12,0-.43-.08-.42,0-.43,0h-.44l-.48,0a4.85,4.85,0,0,0-.55.08l-.23.05-.2,0-.38.1a4.58,4.58,0,0,0-.93.4l-9.16,5.33-.06,0c-.08.05-.14.11-.22.17a3.06,3.06,0,0,0-.29.21s0,0,0,0a3.5,3.5,0,0,0-.27.28l0,.07,0,.08a.44.44,0,0,0-.08.11,1.28,1.28,0,0,0-.11.24s0,0,0,0a.43.43,0,0,1,0,.05.75.75,0,0,0,0,.16,1,1,0,0,0,0,.24v0h0l0,12.4a1.75,1.75,0,0,1,.07-.44s0,0,0,0a2.14,2.14,0,0,1,.2-.4l0-.07a2,2,0,0,1,.34-.38c.08-.07.19-.14.29-.21a2.69,2.69,0,0,1,.27-.2L127.9,50a4.77,4.77,0,0,1,1.31-.51l.2,0a7.29,7.29,0,0,1,1.48-.16H131a7.31,7.31,0,0,1,1.56.17l.05,0A5,5,0,0,1,134,50L181.43,77.4a3.35,3.35,0,0,1,.55.39,1.83,1.83,0,0,1,.7,1.37l0-12.4A2.16,2.16,0,0,0,181.46,65Z'
                />
                <path
                  className='cls-3'
                  d='M231.31,93.32h0a5.44,5.44,0,0,1,0,.57c0,.18-.08.36-.1.55s0,.13-.06.2a3.19,3.19,0,0,1-.1.35s0,.1,0,.14c-.07.22-.17.43-.26.64s-.19.39-.3.58h0a.86.86,0,0,0-.07.1c-.11.16-.22.32-.34.47h0a5.35,5.35,0,0,1-.43.54l-.32.35-.16.18h0A8.69,8.69,0,0,1,228,99c-.22.15-.41.3-.64.45s-.43.28-.66.41h0c-.28.17-.54.31-.8.44s-.5.24-.76.36-.3.13-.45.19l-.45.19c-.27.1-.52.21-.8.3l-.36.11-.67.2a16.18,16.18,0,0,1-1.94.47h0c-.32.07-.66.12-1,.18l-.51.07-.51.08h0c-.33,0-.68.07-1,.09h0c-.36,0-.72.06-1.07.07l-1.08,0a11,11,0,0,1-1.15,0c-.28,0-.54,0-.82,0-.45,0-.87-.06-1.3-.12s-.69-.09-1-.15c-.54-.09-1-.2-1.54-.31l-.86-.21-.9-.26c-.36-.11-.71-.23-1.06-.36s-.46-.17-.69-.27a16.29,16.29,0,0,1-2.06-1h0l-130-75.08a11,11,0,0,1-3.59-3.13,7.12,7.12,0,0,1-.53-.86,5.84,5.84,0,0,1-.66-2.69h0l0,12.41a5.46,5.46,0,0,0,.07.9c.35,2.1,1.92,4.16,4.72,5.77h0l130,75.08a16.15,16.15,0,0,0,2.05,1l.26.13.44.14q.51.2,1.05.36l.44.15.47.11.85.21.49.12,1.05.2h0l.61.09.45,0H212c.22,0,.44.06.66.08l.56,0c.28,0,.55,0,.82,0h0a10.86,10.86,0,0,0,1.15,0l1.07,0h.14l.94-.06.58-.05.46,0c.34,0,.69-.09,1-.15l.73-.12.27,0h0c.34-.07.65-.16,1-.24s.67-.14,1-.23l.48-.15.54-.17c.28-.09.54-.2.8-.29a9.57,9.57,0,0,0,.91-.39c.25-.12.52-.23.76-.36s.52-.27.79-.43l.15-.09.5-.32h0c.23-.14.42-.3.64-.45h0a7.68,7.68,0,0,0,.86-.7l.28-.27c.17-.18.33-.34.48-.53h0a2.29,2.29,0,0,0,.21-.23c.08-.1.15-.21.22-.31h0q.18-.24.36-.51l0,0h0l0,0c.1-.18.2-.37.28-.55s.15-.32.21-.48a1.42,1.42,0,0,0,.05-.16h0c0-.08.06-.17.09-.28a3.58,3.58,0,0,0,.12-.41c0-.18.07-.37.09-.55a3,3,0,0,0,0-.43v-.13l0-12.41Z'
                />
                <path
                  className='cls-1'
                  d='M227.31,99.43l-.66.42-.8.43c-.24.13-.5.24-.76.36s-.58.26-.9.38-.52.21-.8.3l-1,.31a16.18,16.18,0,0,1-1.94.47c-.33.07-.67.12-1,.18l-1,.15q-.51.06-1,.09c-.36,0-.72.06-1.07.07l-1.08,0a11,11,0,0,1-1.15,0c-.28,0-.54,0-.82,0-.45,0-.87-.06-1.3-.12s-.69-.09-1-.15a21.18,21.18,0,0,1-7.11-2.39l-130-75.08c-6.35-3.67-6.38-9.59-.08-13.25s16.51-3.65,22.86,0l130,75.08.72.44.61.43c.22.17.47.35.67.52s.38.35.55.53a4.16,4.16,0,0,1,.5.54,5,5,0,0,1,.51.63,8.32,8.32,0,0,1,.61,1.07,6.46,6.46,0,0,1,.27.6,4.21,4.21,0,0,1,.19.67,5.16,5.16,0,0,1,.09.52,5.65,5.65,0,0,1,0,1.3c0,.18-.07.36-.1.55s-.15.52-.2.69-.17.43-.26.64-.19.4-.3.59-.27.39-.41.57a3.71,3.71,0,0,1-.43.55c-.16.19-.32.35-.48.53A8.69,8.69,0,0,1,228,99C227.73,99.13,227.54,99.28,227.31,99.43Zm-55-25.6,9.16-5.33c1.66-1,1.65-2.54,0-3.51L134,37.58a6.74,6.74,0,0,0-6.06,0l-9.16,5.33c-1.67,1-1.65,2.55,0,3.51l47.47,27.41a6.7,6.7,0,0,0,6.06,0'
                />
                <path
                  className='cls-3'
                  d='M77.5,22.58a16.8,16.8,0,0,0,15.25,0c4.2-2.44,4.18-6.39,0-8.83a16.8,16.8,0,0,0-15.25,0c-4.2,2.44-4.16,6.4,0,8.83'
                />
                <path
                  className='cls-3'
                  d='M207.36,97.49a16.8,16.8,0,0,0,15.25,0c4.19-2.44,4.17-6.39-.05-8.83a16.8,16.8,0,0,0-15.25,0c-4.2,2.44-4.17,6.4.05,8.83'
                />
              </g>
            </g>
            <g
              id='Line_Right'
              data-name='Line Right'
              ref={div => (this.animationElements.lineRight.element = div)}
            >
              <path
                className='cls-2'
                d='M216.92,5.29V99.22a18.56,18.56,0,0,1-3.8,0V5.29Z'
              />
              <path
                className='cls-2'
                d='M216.92,126.93V290.25a16.62,16.62,0,0,1-3.8.07V127h2l1,0,.3,0Z'
              />
              <polygon
                className='cls-2'
                points='216.93 308.49 216.93 349.24 213.12 349.24 213.12 310.71 216.93 308.49'
              />
            </g>
            <g
              id='Line_Middle'
              data-name='Line Middle'
              ref={div => (this.animationElements.lineMiddle.element = div)}
            >
              <path
                className='cls-2'
                d='M114.43,5.29V111a17.29,17.29,0,0,1-3.8-.06V5.29Z'
              />
              <polygon
                className='cls-2'
                points='114.43 126.87 114.43 158.01 110.63 155.82 110.63 126.87 114.43 126.87'
              />
              <path
                className='cls-2'
                d='M114.43,196.84v73a17.82,17.82,0,0,1-3.8.11V194.64Z'
              />
              <polygon
                className='cls-2'
                points='114.43 288.29 114.43 349.24 110.63 349.24 110.63 290.49 114.43 288.29'
              />
            </g>
            <g
              id='Line_Left'
              data-name='Line Left'
              ref={div => (this.animationElements.lineLeft.element = div)}
            >
              <path
                className='cls-2'
                d='M20.27,5.29V111.11a17.32,17.32,0,0,1-3.81,0V5.29Z'
              />
              <path
                className='cls-2'
                d='M20.27,142.47V253.81a18.66,18.66,0,0,1-3.81,0V140.26Z'
              />
              <polygon
                className='cls-2'
                points='20.27 276.8 20.27 349.24 16.46 349.24 16.46 274.59 20.27 276.8'
              />
            </g>
          </g>
        </svg>
      </Container>
    )
  }
}

export default Logo
